import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Roberto Sanchez', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description:
    "Hi there, I'm Roberto Sanchez. I'm product designer and full stack developer who provides innovative solutions to community based problems", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  img: 'avatar.png',
  title: "HI THERE, I'M",
  name: 'ROBERTO SANCHEZ',
  subtitle:
    'I am product designer and full stack developer who loves to bring communities together to solve problems',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'ocotillo-thumbnail.png',
    title: 'Ocotillo Resort & Spa',
    backgroundColor: '#F4C1A6',
    url: '/ocotillo',
  },

  {
    id: nanoid(),
    img: 'style-trial-thumbnail.png',
    title: 'Style Trial',
    backgroundColor: '#7F5CFF',
    url: '/style-trial',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/robb1772',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/roberto-sanchez-1522b01b2/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/ronjovi',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
